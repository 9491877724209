<template>
  <div class="recharge">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-input v-model="memberCode" placeholder="搜索会员码" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="memberName" placeholder="搜索会员名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
      </div>

      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="memberName" label="会员名称"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="150" prop="memberCode" label="会员码"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="170" prop="beforeBalance" label="充值前账户余额(元)"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="amount" label="充值金额(元)"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="120" prop="giveAmount" label="赠送金额(元)"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="170" prop="afterBalance" label="充值后账户余额(元)"></el-table-column>
          <el-table-column show-overflow-tooltip min-width="160" prop="createdTime" label="充值时间"></el-table-column>
        </el-table>
      </div>
    </div>

    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      memberCode: "",
      memberName: "",
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.memberCode = this.$route.query.code || ""
    this.getList()
  },
  methods: {
    getList() {
      this.loading = true
      this.$axios
        .get(this.$api.shopMemberRechargePageList, {
          params: {
            memberName: this.memberName,
            memberCode: this.memberCode,
            page: this.currentPage,
            pageSize: this.pageSize,
          },
        })
        .then(res => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list || []
            this.totalItemsCount = res.data.result.totalCount
          }
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    onSearch() {
      this.tableData = []
      this.currentPage = 1
      this.getList()
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.tableData = []
      this.pageSize = e
      this.getList()
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = []
      this.currentPage = e
      this.getList()
    },
  },
}
</script>

<style scoped lang="scss">
.recharge {
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 200px;

      /deep/ .el-input__inner {
        border-radius: 10px;
      }
    }

    .el-input,
    .el-select,
    .el-button {
      margin-left: 20px;
    }
  }
}
</style>
